<template>
  <div>
    <CSTabBar :checked-tab="checkedTaBar" :tabbar="tabBar" @changeTabBar="changeTabBar"/>
    <template v-if="checkedTaBar === 1">
      <div class="filter-panel">
        <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0;">
          <el-date-picker
              v-model="conditions.startTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择开始时间"
              prefix-icon="el-icon-time"
              style="width: 214px"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <span
            class="inline-gray"
        >
            </span>
        <CSSelect i-width="36rpx" style="width: 230px;">
          <el-date-picker
              v-model="conditions.endTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              prefix-icon="el-icon-time"
              style="width: 214px"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <div style="vertical-align: middle; margin-bottom: 15px; display: inline-block;">
          <input
              v-model="conditions.phone"
              placeholder="搜索手机号/姓名/所属企业/面板编号"
              style="color: #000; width: 250px; height: 30px; padding-left: 10px; border-radius: 4px; border: 1px solid #979797; margin-right: 20px;"
              type="text"
          >
        </div>
        <CSSelect>
          <select
              v-model="conditions.passingMethod"
              style="width: 156px; padding-left: 5px;"
          >
            <option value="">全部通行方式</option>
            <option
                v-for="passingMethodMapping in passingMethodMappingList"
                :key="passingMethodMapping.id"
                :value="passingMethodMapping.id"
            >
              {{ passingMethodMapping.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect>
          <select v-model="conditions.type">
            <option value="">身份不限</option>
            <option v-for="identity in identityList" :key="identity.id" :value="identity.id">
              {{ identity.identity }}
            </option>
          </select>
        </CSSelect>
        <CSSelect>
          <select
              v-model="conditions.deviceType"
              style="width: 156px; padding-left: 5px;"
          >
            <option value="">全部面板类型</option>
            <option
                v-for="deviceType in deviceTypeList"
                :key="deviceType.id"
                :value="deviceType.id"
            >
              {{ deviceType.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect>
          <select
              v-model="conditions.bid"
              style="width: 156px; padding-left: 5px;"
          >
            <option value="">全部楼栋</option>
            <option
                v-for="build in buildList"
                :key="build.id"
                :value="build.code"
            >
              {{ build.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect style="margin-right: 30px;">
          <select v-model="conditions.direction">
            <option value="">方向不限</option>
            <option v-for="item in direction" :key="item.id" :value="item.id">{{ item.type }}</option>
          </select>
        </CSSelect>
        <button
            class="btn btn-primary btn-sm"
            type="button"
            @click="searchTurnstilePassingLog"
        >
          查询
        </button>
      </div>
      <div class="result-panel" style="overflow: visible">
        <div>
          <CSTable :theadTop="filterHeight + 'px'">
            <template v-slot:thead>
              <tr>
                <th>通行时间</th>
                <th>姓名</th>
                <th>手机号</th>
                <th>身份</th>
                <th>身份证</th>
                <th>所属企业</th>
                <th>所在房间</th>
                <th>通行方式</th>
                <th>面板编号</th>
                <th>面板类型</th>
                <th>通行楼栋</th>
                <th>方向</th>
                <th>体温</th>
                <th>图片</th>
              </tr>
            </template>
            <template v-slot:tbody>
              <tr
                  v-for="(log, index) in logList"
                  :key="log + '-' + index"
              >
                <td class="date-td">
                  {{ formatDate(log.time) || '-' }}
                </td>
                <td>{{ log.name || '-' }}</td>
                <td>{{ log.phone || log.link || '-' }}</td>
                <td>{{ userType[log.type] || '-' }}</td>
                <td>{{ log.idCard || '-' }}</td>
                <td>
                  {{ log.company || '-' }}
                </td>
                <td>
                  {{ log.rooms || '-' }}
                </td>
                <td>
                  {{
                    passingMethodMapping[log.method]
                  }}
                </td>
                <td>{{ log.deviceNo }}</td>
                <td>{{ processingPanelType(log.deviceType) }}</td>
                <td>{{ log.bname }}</td>
                <td>
                  {{ log.dir == 1 ? "进入" : "离开" }}
                </td>
                <td>
                  <template v-if="log.bt">
                    {{ log.bt.toFixed(1) }}
                  </template>
                  <template v-else>
                    -
                  </template>
                </td>
                <td>
                    <span
                        v-if="log.photo && log.photo != '-'"
                        class="allow-click"
                        @click="lookStaffFaceImg(log)"
                    >
                      查看
                    </span>
                  <span
                      v-else
                  >
                      -
                    </span>
                </td>
              </tr>
            </template>
          </CSTable>
        </div>


        <!-- 分页 -->
        <Pagination
            v-if="checkedTaBar === 1"
            componentName="Pagination"
            name="pagination"
            style="margin:0px 0 10px"
        />


      </div>
    </template>
    <template v-if="checkedTaBar === 2">
      <div class="config-view one">
        <div class="title-heard " style="padding-left: 20px;position: relative">
          体温设置
        </div>
        <hr>
        <div style="padding: 0 30px">
          <div class="form-item">
            <div>
              体温检测，
            </div>
            <div>
              限行体温（℃)
              <input v-model="restrictConfig.maxAllowTemperature" max="42" min="30" placeholder="请输入"
                     style="margin-left: 30px"
                     type="number">
            </div>
          </div>
          <button class="btn btn-primary" style="margin-top: 22px;" @click="saveConfig">保存</button>
        </div>

      </div>

      <div class="config-view" style="margin-top: 20px">
        <div class="title-heardv" style="padding-left: 30px">
          门卡设置
        </div>
        <hr>
        <div style="padding-left: 30px">
          <div class="form-item">
            <div class="form-item-name" style="width: 88.14px;text-align: right">
              实体门卡
            </div>
            <div>
              <CSRadio v-model="restrictConfig.doorCardRestrict" :items="{true: '提供', false: '不提供'}"/>
            </div>
          </div>
          <div class="config-tip" style="margin-bottom: 0;margin-left: 110px;margin-top: 10px">
            <svg
                aria-hidden="true"
                class="icon"
            >
              <use xlink:href="#icon-menua-zu92"></use>
            </svg>
            如不提供门卡，用户只能刷脸或刷码通过闸机/楼层门禁。
          </div>
          <div v-if="restrictConfig.doorCardRestrict" class="form-item" style="margin-top: 10px">
            <div class="form-item-name" style="width: unset">
              门卡押金（元)
            </div>
            <div>
              <input v-model="restrictConfig.deposit" min="0" placeholder="请输入" type="number">
            </div>
          </div>
          <button class="btn btn-primary" style="margin-top: 20px;" @click="saveConfig">保存</button>
        </div>
      </div>


      <div class="config-view" style="margin-top: 20px">
        <div class="title-heardv" style="padding-left: 30px">
          访客通行设置
        </div>
        <hr>
        <div style="padding-left: 30px">
          <div class="form-item pianli">
            <div class="form-item-name" style="width: 180px;text-align: right">
              访客通行申请访问最大时长
            </div>
            <div style="height: 30px">
              <CSSelect i-width="36rpx">
                <select
                    v-model="operateVisitSetting.visitingMaxDay"
                    :style="{height: '32px',lineHeight:'32px',verticalAlign:'bottom',color:operateVisitSetting.visitingMaxDay?'black':'#999'}"
                >
                  <option value="">请选择最大天数</option>
                  <option
                      v-for="item in visitingMaxDay"
                      :key="item"
                      :value="item">
                    {{ item }}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
          <div class="form-item" style="margin-top: 20px;display: flex;align-items: start">
            <div class="form-item-name" style="width: 180px;text-align: right;flex: 0 0 auto;padding: 12px 0">
              访问原因快捷输入短语
            </div>
            <div>
              <div v-for="item in visitSetting.visitingReason" :key="item" class="hotSearch"
                   style="margin: 0px 20px 0px 0;">
                <span class="text">{{ item }} {{ item.index }}</span>
                <svg
                    aria-hidden="true"
                    class="icon"
                    style="font-size: 14px"
                    @click="deleteReason(item)"
                >
                  <use xlink:href="#icon-menua-zu60"></use>
                </svg>
              </div>
              <div style="padding: 10px 0">
                <button
                    class="btn btn-primary"
                    style="width: 100px; height: 30px; padding: 0;margin-left: 0;"
                    @click="openAddItem"
                >
                  <svg aria-hidden="true" class="icon">
                    <use xlink:href="#icon-menujiahao"></use>
                  </svg>
                  添加
                </button>
              </div>
            </div>
          </div>
          <button class="btn btn-primary" style="margin-top: 20px;" @click="saveReason()">保存</button>

          <!--添加装修项 弹窗-->
          <CSDialog
              :dialog-visible="addItemFlag"
              :dialogTitle="`添加`"
              dialog-width="620px"
              @onClose="addItemFlag = false;reason=null;"
              @onConfirm="addReason()"
          >
            <template v-slot:dialog-content>
              <div class="dialog-form">
                <div class="dialog-form-item">
                  <div class="dialog-form-item-label" style="width:130px">访问原因</div>
                  <div>
                    <input
                        v-model="reason"
                        placeholder="限16个字"
                        style="
                          width: 347px;
                          height: 40px;
                          padding: 0px 10px;
                          border: 1px solid #979797;
                          border-radius: 4px;"
                        type="text"
                    />
                  </div>
                </div>
              </div>
            </template>
          </CSDialog>
        </div>
      </div>
      <div class="config-view" style="margin-top: 20px">
        <div class="title-heardv" style="padding-left: 30px">
          梯控设置
        </div>
        <hr style="margin-bottom: 0">
        <LadderControl></LadderControl>
      </div>
    </template>
    <ViewImageModal componentName="ViewImageModal" name="viewImageModal">
    </ViewImageModal>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import dayjs from "dayjs";
import CSSelect from "@/components/common/CSSelect";
import CSTabBar from "@/components/common/CSTabBar";
import CSRadio from "@/components/common/CSRadio";
import ViewImageModal from "@/components/ViewImageModal";
import TreePanel from "@/components/common/TreePanel";
import CSTree from "@/components/common/CSTree";
import LadderControl from "@/views/DeviceManagement/LadderControl";
import CSDialog from "@/components/common/CSDialog";
import {
  addStaffElevatorUrl,
  getFaceImgUrl,
  getIsSettOpenRoomUrl,
  getUserFaceImage,
  passingLogUrl,
  queryAllSettingUrl,
  queryBuildingUrl,
  queryRestrictConfigUrl,
  queryVisitSettingUrl,
  setRestrictConfigUrl,
  settingVisitSettingUrl
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
  props: {},
  components: {
    CSTable,
    Pagination,
    CSTabBar,
    CSSelect,
    CSRadio,
    ViewImageModal,
    TreePanel,
    CSTree,
    LadderControl,
    CSDialog
  },
  data() {
    return {
      staffId: [],
      checkedStaffs: {},
      staffTreeData: [],
      staffTreeVisible: false,
      checkedTaBar: 1,
      tabBar: {
        1: '通行记录',
        2: '设置',
      },
      filterHeight: 0,
      conditions: {
        uid: "",
        rid: this.$vc.getCurrentRegion().communityId,
        startTime: dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00",
        endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
        passingMethod: "",
        deviceType: "",
        phone: "",
        bid: "",
        type: "",
        direction: "",
      },
      userType: {
        1: '楼内用户',
        2: '访客',
        3: '访客' /*'游客'*/,
        4: '物业员工',
      },
      ownerTypeCdMapping: {
        1001: "业主",
        1002: "家庭成员",
        1003: "租客",
        1004: "访客",
        1005: "企业员工",
      },
      passingMethodMapping: {
        1: "扫码",
        2: "刷脸",
        3: "刷卡",
      },
      logList: [],
      restrictConfig: {
        entranceRestrict: false,
        buildingRestrict: false,
        temperatureRestrict: false,
        exitRestrict: false,
        doorCardRestrict: false,
        depositRestrict: false,
        deposit: '',
        maxAllowTemperature: '',
      },
      passingMethodMappingList: [
        {
          id: 1,
          name: "扫码"
        },
        {
          id: 2,
          name: "刷脸"
        },
        {
          id: 3,
          name: "刷卡"
        }
      ],
      // 面板类型
      deviceTypeList: [
        {
          id: 1,
          name: "闸机(进)"
        },
        {
          id: 2,
          name: "闸机(出)"
        },
        {
          id: 6,
          name: "楼层门禁"
        }
      ],
      // 楼栋列表
      buildList: [],
      identityList: [
        {
          identity: '楼内用户',
          id: 1,
        },
        {
          identity: '访客',
          id: 2,
        },
        {
          identity: '物业员工',
          id: 4
        }
      ],
      direction: [
        {
          type: '进入',
          id: 1,
        },
        {
          type: '离开',
          id: 2,
        }
      ],
      visitingMaxDay: 365,
      reason: null,
      operateVisitSetting: {
        id: null,
        regionCode: null,
        visitingMaxDay: "",
        visitingReason: [],
      },
      visitSetting: {
        id: null,
        regionCode: null,
        visitingMaxDay: "",
        visitingReason: [],
        itemNum: null
      },
      addItemFlag: false
    };
  },
  created() {
    window.addEventListener("keydown", this.searchTurnstilePassingLogDown);
    this.queryBuilding();
    // this.getStaffList();
    //查询指定人员通行电梯
    this.queryElevator();
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.searchTurnstilePassingLogDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.searchTurnstilePassingLogDown);
  },
  mounted() {
    document.body.addEventListener('click', () => {
      this.staffTreeVisible = false;
    })
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
    this.getTurnstilePassRecord();
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.getTurnstilePassRecord(pageNo);
    });
    this.getConfig();
    this.queryVisitSetting();
  },
  methods: {
    openAddItem() {
      this.addItemFlag = true
    },
    // 保存设置
    saveReason() {
      this.operateVisitSetting.id = this.visitSetting.id;
      this.operateVisitSetting.visitingReason = this.visitSetting.visitingReason;
      this.operateVisitSetting.regionCode = this.$vc.getCurrentRegion().code;
      this.$fly.post(settingVisitSettingUrl, this.operateVisitSetting).then(res => {
        if (res.code !== 0) {
          return
        }
        this.queryVisitSetting();
        this.$vc.toast('设置成功');
      })
    },
    //添加访问原因
    addReason() {
      if (this.visitSetting.visitingReason&&this.visitSetting.visitingReason.length >= 10) {
        this.$vc.toast("最多添加10个访问原因");
        this.addItemFlag = false;
        return;
      }
      console.log('this.visitSetting.visitingReason');
      console.log(this.visitSetting);
      console.log(this.visitSetting.visitingReason);
      this.visitSetting.visitingReason.push(this.reason);
      this.addItemFlag = false;
      this.reason=null;
    },
    // 删除元素
    deleteReason(item) {
      this.visitSetting.visitingReason.splice(this.visitSetting.visitingReason.indexOf(item), 1);
    },
    // 查询访客通行设置
    queryVisitSetting() {
      this.$fly.get(queryVisitSettingUrl, {
        regionCode: this.$vc.getCurrentRegion().code
      }).then(res => {
        if (res.code !== 0) {
          return;
        }
        this.visitSetting = {...this.visitSetting,...res.data}
        this.operateVisitSetting.visitingMaxDay = res.data.visitingMaxDay
      })
    },
    //查询指定人员通行电梯
    queryElevator() {
      return this.$fly.get(`${queryAllSettingUrl}/${this.$vc.getCurrentRegion().code}`);
    },
    //保存梯控
    saveTor() {
      this.$fly.post(addStaffElevatorUrl, {
        "regionCode": this.$vc.getCurrentRegion().code, // 园区code 必填
        "staffId": this.staffId  // 员工ID  数组 必填
      }).then(res => {
        if (res.code != 0) {
          return
        }
        this.$vc.toast('设置成功');
      })
    },
    getOpenRoomStaff() {
      return this.$fly.get(getIsSettOpenRoomUrl, {
        staffId: '',
        regionId: this.regionId,
      })
          .then(res => res.data || [])
    },
    // 获取员工列表
    async getStaffList() {
      this.staffTreeData = [];
      let canOpenRoomStaffs = {};
      this.checkedStaffs = {};
      //回显
      let canOpenRoomRes = await this.queryElevator();
      canOpenRoomRes = canOpenRoomRes.data;
      //回显数据

      canOpenRoomRes.forEach(staff => {
        this.checkedStaffs[staff.staffId] = {
          id: staff.id,
          departmentId: staff.departmentId, //必填，部门编号
          departmentName: staff.departmentName, //必填，部门名称
          staffId: staff.staffId, //必填，员工编号
          staffName: staff.staffName, //必填，员工姓名
          staffOfficeName: staff.staffOfficeName, //必填，员工职位
          state: staff.state //设置状态  0-已设置  1-未设置
        };
        staff.state = Number(!staff.state);
        canOpenRoomStaffs[staff.staffId] = staff;
      });
      this.canOpenRoomStaffs = canOpenRoomStaffs;

      //获取员工列表
      const departments = await this.$vc.getAllDepartmentList({});
      console.log("departments::", departments)
      departments.map(async duty => {
        let children = await this.$vc.getAllStaffList({isOnTheJob: true, departmentCode: duty.code});
        children = children.map(child => {
          child.checked = false;
          if (canOpenRoomStaffs.hasOwnProperty(child.id)) {
            child.openId = canOpenRoomStaffs[child.id].id;
            child.checked = !!canOpenRoomStaffs[child.id].state;
          }
          child.allowCheck = true;
          return child;
        })
        const item = {
          ...duty,
          children,
          checked: false,
          allowCheck: true,
        };
        this.staffTreeData.push(item);
      })
    },
    //更改员工
    changeCheckedStaff(checked, checkedStaffs, item) {
      let {canOpenRoomStaffs} = this;
      console.log('是否需要更改', checked);
      console.log(checkedStaffs);
      if (checked) {
        const staff = {
          departmentId: item.departmentCode, //必填，部门编号
          departmentName: item.departmentName, //必填，部门名称
          staffId: item.id, //必填，员工编号
          staffName: item.name, //必填，员工姓名
          staffOfficeName: item.jobTitle, //必填，员工职位
          state: 0 //设置状态  0-已设置  1-未设置
        };
        if (item.openId) {
          staff.id = item.openId;
        }
        checkedStaffs[item.id] = staff;
      } else {
        delete checkedStaffs[item.id];

      }
    },
    //拿取选中的员工
    changeStaff({key: index, source}) {

      const item = source[index];
      const checked = item.checked;
      let {checkedStaffs} = this;
      item.checked = !checked;

      if (item.children) {

        item.children.forEach(child => {
          this.changeCheckedStaff(item.checked, checkedStaffs, child);
          child.checked = item.checked;
        })
      } else {

        this.changeCheckedStaff(item.checked, checkedStaffs, item);
      }


      this.checkedStaffs = checkedStaffs;
      this.staffId = Object.keys(this.checkedStaffs);
    },
    searchTurnstilePassingLogDown(e) {
      if (e.keyCode == 13) {
        if (this.checkedTaBar === 1) {
          this.searchTurnstilePassingLog();
        }

      }
    },
    // 查询楼栋
    queryBuilding() {
      this.$fly
          .post(queryBuildingUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
          })
          .then(res => {
            if (res.code != 0) {
              return;
            }
            this.buildList = res.data.datas;
          })
    },
    // 保存设置
    saveConfig() {
      this.restrictConfig.depositRestrict = this.restrictConfig.deposit && this.restrictConfig.deposit != '';
      if (this.restrictConfig.doorCardRestrict && (this.restrictConfig.deposit === '')) {
        this.$vc.message('无法保存，请输入门卡押金');
        return;
      }
      this.$fly.post(setRestrictConfigUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        ...this.restrictConfig,
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.toast('设置成功');
          })
    },
    // 获取已经设置的配置信息
    getConfig() {
      this.$fly.get(queryRestrictConfigUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.copyObject(res.data, this.restrictConfig);
          })
    },
    /**
     * 修改tab
     * @param {String | Number} 选中tab的唯一标识
     * */
    changeTabBar(index) {
      this.checkedTaBar = +index;
      if (+index === 1) {
        console.log("切换到通行")
        this.getTurnstilePassRecord();
        this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
          this.getTurnstilePassRecord(pageNo);
        });
      } else {
        console.log("切换到xxx")
        // this.getStaffList();
      }
    },
    createTemperature() {
      return Number(36 + Math.random()).toFixed(1);
    },
    formatDate(date) {
      if (date) {
        return dayjs(date).format("YYYY-MM-DD HH:mm");
      }
      return null;
    },
    searchTurnstilePassingLog() {
      this.getTurnstilePassRecord();
    },
    getTurnstilePassRecord(pageNo = 1, pageSize = 10) {
      this.$fly
          .post(passingLogUrl, {
            page: pageNo,
            pageSize,
            ...this.conditions,
            startTime: this.conditions.startTime + ":00",
            endTime: this.conditions.endTime + ":59",
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              })
            }
            this.logList = res.data.datas;
          });
    },
    // 处理面板类型
    processingPanelType(type) {
      let name = "";
      this.deviceTypeList.forEach(item => {
        if (item.id == type) {
          name = item.name;
        }
      })
      return name;
    },
    async lookStaffFaceImg(log) {
      const baseString = await this.getStaffFaceImg(log.uid, log.type);
      this.$vc.emit(this.$route.path, "viewImageModal", "view", [baseString])
    },
    getStaffFaceImg(userId, type) {
      return this.$fly.readFileStream({
        url: type === 4 ? getFaceImgUrl : getUserFaceImage, data: {
          userId,
        }
      })
          .then(res => res)
          .catch(() => {
            this.$vc.message("获取图片失败");
          });
    },
  },
};
</script>
<style lang="stylus" scoped>
.filter-panel
  .cs-select
    &:not(:last-child)
      margin-right 20px

.config-view
  padding 20px 0px
  background #fff
  color #000
  box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)

  .form-item
    font-size 14px
    margin-bottom 10px

    div
      display inline-block

      input
        width 135px
        height 30px
        border-radius 3px
        border 1px solid #979797

    .btn
      width 50px
      height 30px
      font-size 14px
      border-radius 4px
      margin-left 30px
      padding 0

    &-name
      width 84px
      margin-right 20px

  .config-tip
    color #999
    margin-bottom 20px

input::-webkit-input-placeholder
  color: #999;

input::-moz-placeholder
  color: #999;

input::-moz-placeholder
  color: #999;

input::-ms-input-placeholder
  color: #999;

.title-heard
  height: 28px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 28px;
  color: #000000;
  opacity: 1;
  position relative
  margin-left 15px

.title-heard::after
  content: "";
  position: absolute;
  width: 6px;
  height: 18px;
  background: rgba(0, 182, 148, 1);
  left: 8px;
  top: 5px;
  opacity: 1;

.title-heardv
  height: 28px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 28px;
  color: #000000;
  opacity: 1;
  position relative

.title-heardv::after
  content: "";
  position: absolute;
  width: 6px;
  height: 18px;
  background: rgba(0, 182, 148, 1);
  left: 18px !important;
  top: 5px;
  opacity: 1;

.one
  .form-item-name
    width: 112px !important;
    text-align: right !important;

  .config-tip
    margin-left 132px

.config-view
  .btn
    width 50px
    height 30px
    font-size 14px
    border-radius 4px
    padding 0

.room-select
  width 115px
  overflow hidden
  height 30px
  vertical-align top
  display inline-flex !important
  flex-flow row nowrap
  align-items center
  line-height 30px
  padding 0 6px
  position relative

  &.unselect
    color #999

  span
    height 30px
    line-height 30px
    background #1ab394
    padding 0 10px
    text-align center
    display inline-block
    vertical-align middle
    color #fff
    border-radius 15px

    &:not(:last-of-type)
      margin-right 10px

.tree-wrap
  max-width 250px
  position relative
  display inline-block
  vertical-align middle

  .cs-input
    width 215px

.hotSearch {
  padding-top 10px
  padding-bottom 10px
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  div {
    display: flex;
    align-items: center;
  }

  .text {
    background-color: #D8FFF8;
    font-size: 14px;
    color: #00B694;
    border-radius: 15px;
    margin-right: 10px;
    padding: 5px 10px;
  }
}

.dialog-form {
  padding: 27px 30px;
  font-size: 24px;
  color: #000;

  &-item {
    &:not(:last-of-type) {
      margin-bottom: 23px;
    }

    &-label {
      width: 206px;
      height: 33px;
      text-align: right;
      margin-right: 40px;
      display: inline-block;
      vertical-align: middle;
      line-height: 33px;
    }

    & > div {
      display: inline-block;
      vertical-align: middle;

      input {
        &::placeholder {
          color: #999;
        }

        width: 140px;
        height: 40px;
        border-radius: 4px;
        padding: 0 5px;
        border: 1px solid #979797;
        vertical-align: middle;
        padding-left: 10px;
      }
    }
  }
}

.font_style {
  width: 84px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  opacity: 1;
  margin-left 30px;
  vertical-align: middle;
}

</style>
<style>
  .pianli {

  }
</style>
